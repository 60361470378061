import AppProvider from "providers/AppProvider";
import React from "react";
import ReactDOM from "react-dom/client";
import BreakpointsProvider from "providers/BreakpointsProvider";
import SettingsPanelProvider from "providers/SettingsPanelProvider";
import { RouterProvider } from "react-router-dom";
import { router } from "Routes";
import { AuthProvider } from "providers/AuthContext";
import { MetaProvider } from "providers/MetaContext";
import { MasterProvider } from "providers/MasterContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { MsalProvider } from "@azure/msal-react";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "providers/msAuthConfig";
import { ProjectAdminContextProvider } from "providers/ProjectAdminProvider";
import { VersionProvider } from "providers/VersionProvider";
import HelpPanelProvider from "providers/HelpPannelProvider";
import { ToastProvider } from "providers/ToastProvider";

// import 'react-tooltip/dist/react-tooltip.css'

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const msalInstance = new PublicClientApplication(msalConfig);
// Default to using the first account if no account is active on page load
const activeAccount = msalInstance.getActiveAccount();
if (!activeAccount && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const allAccounts = msalInstance.getAllAccounts();
  msalInstance.setActiveAccount(allAccounts[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

root.render(
  <VersionProvider>
    <MsalProvider instance={msalInstance}>
      <GoogleOAuthProvider clientId="247195563552-vo0uaaijd4uj3rs61q4c81lcdvbhtt80.apps.googleusercontent.com">
        <AuthProvider>
          <AppProvider>
            <ToastProvider>
              <SettingsPanelProvider>
                <HelpPanelProvider>
                  <BreakpointsProvider>
                    <MetaProvider>
                      <MasterProvider>
                        <ProjectAdminContextProvider>
                          <RouterProvider router={router} />
                        </ProjectAdminContextProvider>
                      </MasterProvider>
                    </MetaProvider>
                  </BreakpointsProvider>
                </HelpPanelProvider>
              </SettingsPanelProvider>
            </ToastProvider>
          </AppProvider>
        </AuthProvider>
      </GoogleOAuthProvider>
    </MsalProvider>
  </VersionProvider>
);
