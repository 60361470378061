import { useLocation } from "react-router-dom";
import { NavPositionVariant, NavTopShapeVariant } from "config";
import { useAppContext } from "providers/AppProvider";
import { useEffect } from "react";
import useSettingsMountEffect from "hooks/useSettingsMountEffect";
import Unicon from 'components/base/Unicon';
import { UilCube,UilAbacus } from '@iconscout/react-unicons';
import { Col, Card, Row } from "react-bootstrap";
import { useAuth } from "providers/AuthContext";
import {Link, NavigateFunction, useNavigate } from "react-router-dom";

const AdminHome = () => {
  const { config: { theme, navbarPosition }, setConfig, } = useAppContext();
  const {  workSpaceTkn } = useAuth();
  const navigation = useNavigate() as NavigateFunction;
    //check workspace 
    useEffect(() => {
      if (!workSpaceTkn) {
          navigation("/admin/all-workspace/list")
      }
  },[])

  //set top navigation
  useEffect(() => {
    const value = "horizontal";
    const value2 = 'slim';
    setConfig({
      navbarPosition: value as NavPositionVariant,
      navbarTopShape: value2 as NavTopShapeVariant
    });

  }, [])

  useSettingsMountEffect({
    showSettingPanelButton: false,
  });

  return (
    <>
      <div >

        <div >
          <div className="d-flex flex-wrap mb-4  justify-content-between">
            <h2 className="mb-0">
            <span className="me-3">{" Admin"}</span>
            </h2>
          </div>

          <div className="d-md-block d-block">
      
          <Row className="row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-4">
            <Col className="mb-3">
            <Card className="px-3 py-2">
              {/* <Link to="/admin/entity/list" className="text-decoration-none"> */}
              <div className="vervieww">
                <p className="fs-8 title_overview">Total Entity</p>
              </div>
              <div className="overviewNumbers d-flex justify-content-between align-items-center">
                <h3 className="fw-bold">56</h3>
                <Unicon icon={UilCube} size={28} color="#31374A"/>
              </div>
              {/* </Link> */}
           
            </Card>
            </Col>
            <Col className="mb-3">
            <Card className="px-3 py-2">
              {/* <Link to="/admin/users" className="text-decoration-none"> */}
              <div className="vervieww">
                <p className="fs-8 title_overview">Total Users</p>
              </div>
              <div className="overviewNumbers d-flex justify-content-between align-items-center">
                <h3 className="fw-bold">26</h3>
                <Unicon icon={UilCube} size={26} color="#31374A"/>
              </div>
              {/* </Link> */}
           
            </Card>
            </Col>
            <Col className="mb-3">
            <Card className="px-3 py-2">
            {/* <Link to="/admin/projects" className="text-decoration-none"> */}
            <div className="vervieww">
                <p className="fs-8 title_overview">Total Projects </p>
              </div>
              <div className="overviewNumbers d-flex justify-content-between align-items-center">
                <h3 className="fw-bold">20</h3>
                <Unicon icon={UilCube} size={26} color="#31374A"/>
              </div>
            {/* </Link> */}
        
            </Card>
            </Col>
            <Col className="mb-3">
            <Card className="px-3 py-2">
            {/* <Link to="/admin/meta" className="text-decoration-none"> */}
            <div className="vervieww">
                <p className="fs-8 title_overview">Meta</p>
              </div>
              <div className="overviewNumbers d-flex justify-content-between align-items-center">
                <h3 className="fw-bold">56</h3>
                <Unicon icon={UilAbacus} size={26} color="#31374A"/>
              </div>
            {/* </Link> */}
        
            </Card>
            </Col>
            {/* <Col className="mb-3">
            <Card className="px-3 py-2">
              <div className="vervieww">
                <p className="fs-8 title_overview">Master</p>
              </div>
              <div className="overviewNumbers d-flex justify-content-between align-items-center">
                <h3 className="fw-bold">56</h3>
                <Unicon icon={UilCube} size={26} color="#31374A"/>
              </div>
            </Card>
            </Col> */}
          </Row>

          </div>

        </div>
      </div>
    </>

  );
};

export default AdminHome;
