import { faList } from "@fortawesome/free-solid-svg-icons";
import Unicon from 'components/base/Unicon';
import { UilEnvelopeDownload, UilArchive, UilClipboardNotes, UilInvoice, UilThLarge, UilBriefcaseAlt, UilCalendarAlt, UilAbacus, UilEye, UilTrash, UilUnlock, UilLock, UilQuestionCircle } from '@iconscout/react-unicons';
import { Tooltip } from 'react-tooltip'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Constants from "common/Constants";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { useEffect, useState, useRef } from "react";
import { Link, NavigateFunction, useNavigate, useParams } from "react-router-dom";
import PhoenixDocCard from "components/base/PhoenixDocCard";
import { ChangeEvent } from "react";
import { Button, Card, Col, FloatingLabel, Form, Row, Spinner, Table } from "react-bootstrap";
import { useAppContext } from "providers/AppProvider";
import { NavPositionVariant, NavTopShapeVariant } from "config";
import useSettingsMountEffect from "hooks/useSettingsMountEffect";
import { projectsTablecolumns } from "components/tables/customTables/ProjectsTablesList";
import { useAuth } from "providers/AuthContext";
import Avatar from "components/base/Avatar";
import ToggleViewButton from "components/common/ToggleViewbutton";
import Filtershort from "./Filtershort";
import { useMaster } from "providers/MasterContext";
import { geCategoryNameById, getPhaseNameById } from "utils/getNames";
import CustomPagination from "components/sp-common/CustomPagination";
import calculateSerialNumber from "common/calculateSerialNumber";
import apiCall from "services/api";
import SearchInput from "components/sp-common/SearchInput";
import { useProjectAdminContext } from "providers/ProjectAdminProvider";
import PageBreadcrumb, { PageBreadcrumbItem } from "components/common/PageBreadcrumb";
import { getPageBreadCrumbs } from "services/getPageBreadCrumbs";
import { ToastContainer, toast } from "react-toastify";
import HelpPanel from "components/settings-panel/HelpPanel";
import { useHelpPanelContext } from "providers/HelpPannelProvider";
import { handleCutomError } from "services/handleCutomError";

interface UserPermission {
  id: number;
  user_id: number;
  project_id: number;
  role_id: number;
  role_in_project: string;
  description: string | null;
  last_action_datetime: number;
  is_user_watching: number;
  is_project_admin: number;
  is_story_admin: number;
  is_meeting_admin: number;
  is_diary_admin: number;
  status: number;
}

interface ProjectData {
  id: number;
  title: string;
  short_name: string;
  alias: string;
  description: string;
  parent_id: number | null;
  start_date: Date;
  end_date: Date;
  editable: number;
  category: number;
  phase: number;
  donar: string;
  entity_id: number;
  status: number;
  projectlogo: string;
  serialNumber: number;
  userpermission: UserPermission;
}

// Define the type for the page filters state
type PageFiltersState = {
  shorting: string,
  titleSearch: string,
  category: string,
  phase: string,
  status: string,
  editable: string,
  watching: string
};

const ProjectListMain = () => {
  const initialBreadcrumb: PageBreadcrumbItem[] = [];
  const [pageName, setPageName] = useState("ProjectList")
  const { userTkn, workSpaceTkn, signOut } = useAuth();
  const { helpPanelConfig, setHelpPanelConfig } = useHelpPanelContext()
  const [breadCrumb, setBreadCrumb] = useState<PageBreadcrumbItem[]>(initialBreadcrumb);
  const { workspace } = useParams();
  const { setAdminValues } = useProjectAdminContext();
  const navigation = useNavigate() as NavigateFunction; // Explicitly define the type

  const { projCategory, phases } = useMaster();

  const [data, setData] = useState<ProjectData[] | null>(null);
  const [summary, setSummary] = useState<any | null>({
    "total": 0,
    "page": 1,
    "pageSize": 12,
    "total_page": 1,
  });
  const [indexValueFirst, setValueFirst] = useState<number | null>(1);
  const [indexValueLast, setValueLast] = useState<number | null>(1);
  const tableRef = useRef<HTMLTableElement | null>(null);

  //for filters
  const [pageIndex, setPageIndex] = useState<number | null>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageFilters, setPageFilters] = useState<PageFiltersState>({
    shorting: '2',
    titleSearch: '',
    category: '',
    phase: '',
    status: '1',
    editable: '1',
    watching: '0'
  })


  const [toggleView, setToggleView] = useState<boolean>(false);

  //for card view
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    config: { theme, navbarPosition },
    setConfig,
  } = useAppContext();

  //check workspace
  useEffect(()=>{
    if(!workSpaceTkn){
      navigation("/admin/all-workspace/list")
    }
  },[])


  //set top navigation
  useEffect(() => {
    const value = "horizontal";
    const value2 = "slim";

    setConfig({
      navbarPosition: value as NavPositionVariant,
      navbarTopShape: value2 as NavTopShapeVariant,
    });
  }, []);
  // called when filter changed
  useEffect(() => {
    setData(null);
    setSummary({
      "total": 0,
      "page": 1,
      "pageSize": 12,
      "total_page": 1,
    });
    setPageFilters({
      shorting: '2',
      titleSearch: '',
      category: '',
      phase: '',
      status: '1',
      editable: '1',
      watching: '0'
    });
  }, [workspace]);

  useEffect(() => {

    const fetchData = async () => {
      try {
        setLoading(true); // Set loading to true

        const response = await apiCall({
          url: `project/list?expand=userpermission&page=${pageIndex}&pageSize=12&projectCardOrder=${pageFilters.shorting}&category=${pageFilters.category}&phase=${pageFilters.phase}&editable=${pageFilters.editable}&status=${pageFilters.status}&watching=${pageFilters.watching}`,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            'workspace': workSpaceTkn,
          },
        });

        if (response?.status === 403) {

        }
        if (response?.status === 200) {
          // Calculate serial numbers for the projects on the current page
          const updatedProjects = response?.data?.data?.projects.map((project: any, index: any) => ({
            ...project,
            serialNumber: calculateSerialNumber(pageIndex || 1, index, 12) // Assuming 10 items per page
          }));


          setData(updatedProjects);
          let summary = response?.data?.data?.summary
          setSummary({
            "total": summary.total,
            "page": summary.page,
            "pageSize": summary.pageSize,
            "total_page": summary.total_page,
          });
          setLoading(false);
        } else {
          console.error("Api Failed");
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false); // Set loading to false after the API call is completed
      }
    };
    fetchData();
  }, [userTkn, workspace, pageIndex, pageFilters.shorting, pageFilters.category, pageFilters.phase, pageFilters.status, pageFilters.editable, pageFilters.shorting, pageFilters.watching]);

  //update Breadcrumb
  useEffect(() => {
    getPageBreadCrumbs({ pageName, workspace, setBreadCrumb });
  }, [workspace, pageName, setBreadCrumb]);


  const searchInputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPageFilters((prevData) => ({
      ...prevData,
      titleSearch: e.target.value
    }))
  };

  const handleSearchInputReq = (value: string) => {
    // You can use the titleSearch state value to filter your data
    const fetchData = async () => {
      let searchUrl = ""
      if (value === "search") {
        searchUrl = `project/list?expand=userpermission&page=${pageIndex}&pageSize=12&projectCardOrder=${pageFilters.shorting}&category=${pageFilters.category}&title=${pageFilters.titleSearch}`
      } else {
        searchUrl = `project/list?expand=userpermission&page=${pageIndex}&pageSize=12&projectCardOrder=${pageFilters.shorting}&category=${pageFilters.category}&title=`
      }

      try {
        setLoading(true);
        const response = await apiCall({
          url: searchUrl,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            'workspace': workSpaceTkn,
          },
        });

        if (response?.status === 200) {
          const updatedProjects = response?.data?.data?.projects.map((project: any, index: any) => ({
            ...project,
            serialNumber: calculateSerialNumber(pageIndex || 1, index, 12) // Assuming 10 items per page
          }));


          setData(updatedProjects);
          let summary = response?.data?.data?.summary
          setSummary({
            "total": summary.total,
            "page": summary.page,
            "pageSize": summary.pageSize,
            "total_page": summary.total_page,
          });
          setLoading(false);
        } else {
          console.error("error message:");
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  };

  const handleOnBlur = async (value: any) => {

    if (value !== "") {
      let searchUrl = ""

      searchUrl = `project/list?expand=userpermission&page=${pageIndex}&projectCardOrder=${pageFilters.shorting}&category=${pageFilters.category}&title=${pageFilters.titleSearch}`;

      try {
        setLoading(true);
        const response = await apiCall({
          url: searchUrl,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            'workspace': workSpaceTkn,
          },
        });

        if (response?.status === 200) {
          const updatedProjects = response?.data?.data?.projects.map((project: any, index: any) => ({
            ...project,
            serialNumber: calculateSerialNumber(pageIndex || 1, index, 10) // Assuming 10 items per page
          }));


          setData(updatedProjects);
          let summary = response?.data?.data?.summary
          setSummary({
            "total": summary.total,
            "page": summary.page,
            "pageSize": summary.pageSize,
            "total_page": summary.total_page,
          });
          setLoading(false);
        } else {
          console.error("error message:");
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false);
      }


    }
  }

  useSettingsMountEffect({
    showSettingPanelButton: false,
  });

  const table = useAdvanceTable({
    data: data || [],
    columns: projectsTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    // selection: true
  });


  const handlePageFilters = (value: string) => {
    setPageFilters((prevData) => ({
      ...prevData,
      titleSearch: "",
    }));
  }
  useEffect(() => {
    // Now 'tableRef.current' contains the reference to the table element
    const table = tableRef.current;

    if (table) {
      const firstRow = table?.rows[1];
      const lastRowIndex = table?.rows.length - 1;
      const lastRow = table?.rows[lastRowIndex];

      if (firstRow) {
        const firstCell = firstRow.cells[0];
        if (firstCell) {
          const cellValue = firstCell.textContent || firstCell.innerText;

          let value = Number(cellValue)
          setValueFirst(value)
        }
      }

      if (lastRow) {
        const firstCell = lastRow.cells[0];
        if (firstCell) {
          const cellValue = firstCell.textContent || firstCell.innerText;

          let value = Number(cellValue)
          setValueLast(value)
        }
      }
    }
  }, [data]); // This effect runs once when the component mounts
  //for ongoing/ archive/ deleted
  const getStatusIcon = (status: number) => {
    if (status === 0) return <Unicon icon={UilTrash} size={21} color="" fill="#6E7891" />
    else if (status === 1) return ""
    else if (status === 2) return <Unicon icon={UilArchive} size={19} color="#6E7891" />
    else return <div></div>
  }

  //for Editable/ noeditable
  const getStatusIcon2 = (status: number) => {
    if (status === 0) return <Unicon icon={UilLock} size={21} color="" fill="#6E7891" />
    else if (status === 1) return <Unicon icon={UilUnlock} size={21} color="" fill="#8FC644" />
    else return <div></div>
  }

  const handleDropDown = (value: any) => {

    setPageFilters((prevData) => ({
      ...prevData,
      shorting: value
    }))
  }

  const handleWatchingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked ? '1' : '0';
    setPageFilters((prevState: PageFiltersState) => ({
      ...prevState,
      watching: value,
    }));
  };

  //set project admin value
  const handleClick = (item: any) => {
 
    let permission = item.userpermission;
    // Update the values when the link is clicked
    setAdminValues({
      is_diary_admin: permission.is_diary_admin,
      is_meeting_admin: permission.is_meeting_admin,
      is_project_admin: permission.is_project_admin,
      is_story_admin: permission.is_story_admin,
    });
  };

  return (
    <div>
      <PageBreadcrumb items={breadCrumb} navigation={navigation} />
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Projects </span>{" "}
          </h2>
          <button className="btn p-1 fw-bolder" onClick={() => {
            setHelpPanelConfig(prevConfig => ({
              ...prevConfig,
              openHelpPanel: !prevConfig.openHelpPanel,
              helpPage: "ProjectListMain"
            }));
          }}>
            < Unicon icon={UilQuestionCircle} size={20} id="help-btn" />

          </button>
          {/* <Link
            className="btn btn-outline-primary px-5"
            to={`/${workspace}/project/create-project`}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            {Constants.new_project}
          </Link> */}
        </div>
        <Row className="g-3 justify-content-between  mb-3">
          <Col xs={12} className="col-md-10">
            <div className="d-flex align-items-center gap-2 flex-wrap ">
              <Filtershort handleDropDown={handleDropDown} value={pageFilters.shorting} />
              <SearchInput
                pageFilters={pageFilters}
                handleSearchInputChange={searchInputOnChange}
                handleSearchInputBlur={handleSearchInputReq}
                handlePageFilters={handlePageFilters}
                handleOnBlur={handleOnBlur}
                title="Title"
              />
              <FloatingLabel controlId="lead-woner" label="CATEGORY">
                <Form.Select
                  onChange={(e) => {
                    setLoading(true)
                    setPageFilters((prevData) => ({
                      ...prevData,
                      category: e.target.value
                    }))
                  }}
                >
                  <option value={""} key={"a1"}>{"All"} </option>
                  {projCategory &&
                    projCategory
                      .sort((a, b) => a.orderby - b.orderby) // Sort by orderby property
                      .map((item, index: number) => {
                        return (
                          <option value={item.id} key={item.id}>
                            {item?.title}
                          </option>
                        );
                      })}
                </Form.Select>
              </FloatingLabel>
              <FloatingLabel controlId="lead-woner" label="PHASE">
                <Form.Select
                  onChange={(e) => {
                    setLoading(true)
                    setPageFilters((prevData) => ({
                      ...prevData,
                      phase: e.target.value
                    }))
                  }}
                >
                  <option value={""} key={"a1"}>
                    {"All"}
                  </option>
                  {phases &&
                    phases?.map((item, index: number) => {
                      return (
                        <option value={item.id} key={item.id}>
                          {item?.title}
                        </option>
                      );
                    })}
                </Form.Select>
              </FloatingLabel>
              <Form.Select
                onChange={(e) => {
                  setLoading(true)
                  setPageFilters((prevData) => ({
                    ...prevData,
                    status: e.target.value
                  }))
                }}
                size="lg"
                className="select_form section_formm"
              >
                {/* <option value="">All</option> */}
                <option value="1">On-going</option>
                <option value="2">Archived</option>
                {/* <option value="0">Deleted</option> */}
              </Form.Select>
              <FloatingLabel controlId="lead-woner" label="STATUS">
                <Form.Select
                  onChange={(e) => {
                    setLoading(true)
                    setPageFilters((prevData) => ({
                      ...prevData,
                      editable: e.target.value
                    }))
                  }}
                >
                  {/* <option value="">All</option> */}
                  <option value="1">enable</option>
                  <option value="0">Disable</option>
                </Form.Select>
              </FloatingLabel>
              <Form.Check type="checkbox" className="mb-0 ms-md-3">
                <Form.Check.Input
                  type="checkbox"
                  name="remember-me"
                  id="remember-me"
                  checked={pageFilters.watching === '1'}
                  onChange={handleWatchingChange}
                />
                <Form.Check.Label htmlFor="remember-me" className="mb-0">
                  <span style={{ color: "#8A94AD", fontWeight: "700" }}>WATCHING</span>
                </Form.Check.Label>
              </Form.Check>

            </div>
          </Col>
          <Col xs={12} className="col-md-2">
            <div className="d-flex align-items-center gap-2 justify-content-end">
              <div className="d-md-block d-none">
                <ToggleViewButton
                  tooltip="List view"
                  active={toggleView === false}
                  onClick={() => {
                    setToggleView(false);
                  }}
                >
                  <FontAwesomeIcon icon={faList} className="fs-9" />
                </ToggleViewButton>
              </div>
              <div className="d-md-block d-none">
                <ToggleViewButton
                  tooltip="Card view"
                  active={toggleView === true}
                  onClick={() => {
                    setToggleView(true);
                  }}
                >
                  <Unicon icon={UilThLarge} size={14} />
                </ToggleViewButton>
              </div>
            </div>
          </Col>
        </Row>
        <div className="d-md-block">
          {!toggleView ? (

            <PhoenixDocCard className="p-sm-4 p-2 mt-3 ">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" variant="light" />
                </div>
              ) :
                (
                  <>
                    <Table
                      className="phoenix-table fs-9 phoenix-table fs-9 project-table project_padding border rounded"
                      size="md"
                      striped
                      hover
                      responsive
                      ref={tableRef}
                    >
                      <thead>
                        <tr id={"a1"} >
                          <th style={{ width: "2%", minWidth: "10px", padding: "16px 0px" }}>#</th>
                          <th
                            className="px-1"
                            style={{ width: "25%", minWidth: "200px" }}
                          >
                            {Constants.title}
                          </th>
                          <th
                            className="px-1"
                            style={{ width: "6%", minWidth: "80px" }}
                          >
                            {Constants.category}
                          </th>
                          <th
                            className="px-1"
                            style={{ width: "7.5%", minWidth: "100px" }}
                          >
                            {Constants.phase}
                          </th>
                          {/* <th
                            className="px-1"
                            style={{ width: "9.5%", minWidth: "100px" }}
                          >
                            {Constants.tags}
                          </th> */}
                          <th
                            className="px-1"
                            style={{ width: "7.5%", minWidth: "100px" }}
                          >
                            {Constants.watching}(PIN)
                          </th>
                          <th
                            className="px-1"
                            style={{ width: "7.5%", minWidth: "100px" }}
                          >
                            {Constants.status}
                          </th>
                          <th
                            className="px-1"
                            style={{ width: "7.5%", minWidth: "100px" }}
                          >
                            {Constants.links}
                          </th>

                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data.map((item: ProjectData, index: number) => {

                            return (
                              <tr key={item?.id || index + 1} className={`${item?.status === 2 ? 'archive-row' : ''}`}>
                                <td>{item?.serialNumber}</td>
                                <td>
                                  <>
                                    <div className="d-flex gap-1 align-items-center">
                                      <div className="avatar_width">
                                        {
                                          item?.projectlogo ?
                                            <Avatar src={item?.projectlogo} size="l" />
                                            :
                                            <Avatar size="l" variant="name" className="me-1">{item?.short_name?.slice(0, 2)}</Avatar>
                                        }
                                      </div>
                                      <div>
                                        <Link
                                          state={{ user: item }}
                                          to={{
                                            pathname: `/${workspace}/project/${item.alias}/overview`,
                                          }}

                                          onClick={() => handleClick(item)}
                                          className="text-decoration-none fw-semi-bold fs-8 "
                                        >
                                          {item?.title.length > 60 ? `${item.title.substring(0, 60)}...` : item.title}
                                        </Link>
                                        <div className="d-flex gap-2 align-items-center">
                                          {/* {getEntityNameById(Number(item.entity_id), entity)} */}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                </td>
                                <td>
                                  {geCategoryNameById(Number(item?.category), projCategory)}
                                </td>
                                <td>
                                  {getPhaseNameById(Number(item?.phase), phases)}
                                </td>
                                <td> {item?.userpermission?.is_user_watching === 1 ?
                                  <Unicon icon={UilEye} size={21} fill="#6E7891" />
                                  : ""}
                                </td>
                                <td>
                                  <div className="d-flex gap-2">
                                    {getStatusIcon2(item.editable)}
                                    {getStatusIcon(item.status)}
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex gap-2 justify-content-start ">
                                    <Button variant='phoenix-primary' className="p-1 project_list-btn">
                                      < Unicon icon={UilEnvelopeDownload} size={20} id="not-clickable" />
                                      <Tooltip anchorSelect="#not-clickable">Dairy</Tooltip>
                                    </Button>

                                    <Button variant='phoenix-primary' className="p-1 project_list-btn">
                                      <Unicon icon={UilAbacus} size={20} id="Meeting" />
                                      <Tooltip anchorSelect="#Meeting" clickable>Meeting</Tooltip>
                                    </Button>
                                    <Button variant='phoenix-primary' className="p-1 project_list-btn">
                                      <Unicon icon={UilClipboardNotes} size={20} id="notes" />
                                      <Tooltip anchorSelect="#notes" clickable>Notes</Tooltip>
                                    </Button>

                                    <Button variant='phoenix-primary' className="p-1 project_list-btn">
                                      <Unicon icon={UilInvoice} size={20} id="ActionPoint" />
                                      <Tooltip anchorSelect="#ActionPoint" clickable>Action Item</Tooltip>
                                    </Button>

                                    <Button variant='phoenix-primary' className="p-1 project_list-btn">
                                      <Unicon icon={UilBriefcaseAlt} size={20} id="Story" />
                                      <Tooltip anchorSelect="#Story" clickable>Story</Tooltip>
                                    </Button>

                                    <Button variant='phoenix-primary' className="p-1 project_list-btn">

                                      <Unicon icon={UilCalendarAlt} size={20} id="calender" />
                                      <Tooltip anchorSelect="#calender" clickable>Calender</Tooltip>
                                    </Button>
                                    <Button variant='phoenix-primary' className=" pb-0 px-2 pt-1 project_list-btn btn-wdbs">

                                      <h4 id="WBS" className="mb-0 wbsdesign">W</h4>
                                      <Tooltip anchorSelect="#WBS" clickable>WBS</Tooltip>
                                    </Button>
                                  </div>

                                </td>
                              </tr>
                            );


                          })}
                      </tbody>
                    </Table>

                    {summary?.total !== 0 ? <Row className="align-items-center pt-3 row">
                      <Col className="d-flex fs-9">
                        <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900">
                          {indexValueFirst} to {indexValueLast}
                          <span className="text-600"> items of </span>
                          {summary?.total}
                        </p>
                      </Col>
                      <Col xs="auto">
                        <CustomPagination
                          pageIndex={pageIndex}
                          totalPage={summary?.total_page}
                          activePage={Number(summary?.page)}
                          setPageIndex={setPageIndex}
                        />
                      </Col>
                    </Row>
                      :
                      <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900 mt-2">
                        <span className="text-600"> No record found !!</span>
                      </p>
                    }
                  </>
                )
              }
            </PhoenixDocCard>
          ) : (
            <div ref={containerRef}>
              <Row className="g-3 mb-4 ">
                {data && Array.isArray(data) && data.map((project: ProjectData, index: number) => {

                  return (
                    <Col xs={12} md={6} xl={4} xxl={3} key={index}>
                      <Card className={`h-100 hover-actions-trigger ${project?.status === 2 ? 'archive-row' : ''}`} >
                        <Card.Body>
                          <div className="d-flex align-items-center gap-2 mb-2">
                            <div>
                              <div className="avatar_width">
                                {
                                  project?.projectlogo ?
                                    <Avatar src={project?.projectlogo} size="l" />
                                    :
                                    <Avatar size="l" variant="name" className="me-1">{project?.short_name?.slice(0, 2)}</Avatar>
                                }
                              </div>

                            </div>
                            <div>
                              <Link
                                state={{ user: project }}
                                to={{
                                  pathname: `/${workspace}/project/${project.alias}/overview`,
                                }}

                                onClick={() => handleClick(project)}
                                className="text-decoration-none fw-bold fs-8 "
                              >
                                {project?.title}
                              </Link>
                            </div>
                          </div>
                          <div className="d-lg-flex  text-700 fw-semi-bold justify-content-between">
                            <div className="fs-9 mt-2">
                              {"Category: "}
                              <span className="text-1100">
                                {
                                  geCategoryNameById(Number(project?.category), projCategory)
                                }
                              </span>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="fs-9 mt-2">
                                {"Phase: "}
                                <span className="text-1100">
                                  {getPhaseNameById(Number(project?.phase), phases)
                                  }

                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="border-top mt-3">
                            <Row className="d-flex  text-700 fw-semi-bold mt-2 align-items-center">
                              <Col className=" col-4">
                                <div className="d-flex gap-2 align-items-center">
                                  {project?.userpermission?.is_user_watching === 1 ?
                                    <Unicon icon={UilEye} size={21} fill="#6E7891" />
                                    : ""}
                                  {
                                    <div className="d-flex gap-2">
                                      {getStatusIcon2(project.editable)}
                                      {getStatusIcon(project.status)}
                                    </div>
                                  }
                                </div>
                              </Col>
                              <Col className="col-8 mobile_devicesIcon">
                                <div className="d-flex gap-2 justify-content-end ">
                                  <Button variant='phoenix-primary' className="p-1 project_list-btn">
                                    < Unicon icon={UilEnvelopeDownload} size={20} id="not-clickable" />
                                    <Tooltip anchorSelect="#not-clickable">Dairy</Tooltip>
                                  </Button>

                                  <Button variant='phoenix-primary' className="p-1 project_list-btn">
                                    <Unicon icon={UilAbacus} size={20} id="Meeting" />
                                    <Tooltip anchorSelect="#Meeting" clickable>Meeting</Tooltip>
                                  </Button>
                                  <Button variant='phoenix-primary' className="p-1 project_list-btn">
                                    <Unicon icon={UilClipboardNotes} size={20} id="notes" />
                                    <Tooltip anchorSelect="#notes" clickable>Notes</Tooltip>
                                  </Button>

                                  <Button variant='phoenix-primary' className="p-1 project_list-btn">
                                    <Unicon icon={UilInvoice} size={20} id="ActionPoint" />
                                    <Tooltip anchorSelect="#ActionPoint" clickable>Action Item</Tooltip>
                                  </Button>

                                  <Button variant='phoenix-primary' className="p-1 project_list-btn">
                                    <Unicon icon={UilBriefcaseAlt} size={20} id="Story" />
                                    <Tooltip anchorSelect="#Story" clickable>Story</Tooltip>
                                  </Button>

                                  <Button variant='phoenix-primary' className="p-1 project_list-btn">

                                    <Unicon icon={UilCalendarAlt} size={20} id="calender" />
                                    <Tooltip anchorSelect="#calender" clickable>Calender</Tooltip>
                                  </Button>
                                  <Button variant='phoenix-primary' className=" pb-0 px-2 pt-1 project_list-btn btn-wdbs">

                                    <h4 id="WBS" className="mb-0 wbsdesign">W</h4>
                                    <Tooltip anchorSelect="#WBS" clickable>WBS</Tooltip>
                                  </Button>
                                </div>

                              </Col>
                            </Row>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  )
                }
                )}
              </Row>
              {summary?.total !== 0 ? <Row className="align-items-center pt-3 row">
                <Col className="d-flex fs-9">
                  <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900">
                    {indexValueFirst} to {indexValueLast}
                    <span className="text-600"> items of </span>
                    {summary?.total}
                  </p>
                </Col>
                <Col xs="auto">
                  <CustomPagination
                    pageIndex={pageIndex}
                    totalPage={summary?.total_page}
                    activePage={Number(summary?.page)}
                    setPageIndex={setPageIndex}
                  />
                </Col>
              </Row>
                :
                <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900 mt-2">
                  <span className="text-600"> No record found !!</span>
                </p>
              }
            </div>
          )}
        </div>
      </AdvanceTableProvider>
      <ToastContainer />
      <HelpPanel />
    </div>
  );
};

export default ProjectListMain;