import {
  useLocation,
  Link,
  useNavigate,
  NavigateFunction,
  useParams,
} from "react-router-dom";
import { NavPositionVariant, NavTopShapeVariant } from "config";
import { useAppContext } from "providers/AppProvider";
import { useEffect, useState } from "react";
import useSettingsMountEffect from "hooks/useSettingsMountEffect";
import Unicon from "components/base/Unicon";
import { UilCube, UilFilePlusAlt, UilPlus } from "@iconscout/react-unicons";
import { Col, Card, Row, Nav, NavLink, Container } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import apiCall from "services/api";
import { useAuth } from "providers/AuthContext";
import { handleCutomError } from "services/handleCutomError";
import { toast, ToastContainer } from "react-toastify";
interface DataItem {
  id: number;
  title: string;
  body: string; // JSON string representing the `EditorBody` structure
}
const PrivacyPolicy = () => {
  return (
    <Container >
      <Row>
        <Col md={12}>
          <h3>Spider Android App: Privacy Policy</h3>
          <p>
            Spider has built the Spider Project Management App for the Android
            platform as a Free App. This service is provided at no cost and is
            intended for use as is. This page is used to inform visitors about
            our policies regarding the collection, use, and disclosure of
            Personal Information if anyone decides to use our service. If you
            choose to use our service, then you agree to the collection and use
            of information under this policy.
          </p>
          <h4>Information Collection and Use</h4>
          <p>
            We collect limited personal information to provide essential
            features of the app. Specifically, we use Google Auth to enable
            secure authentication and Firebase Messaging for sending
            notifications.
          </p>
          <p>
            The app also uses third-party services that may collect information
            used to identify you. The links to the privacy policies of
            third-party service providers used by the app are as follows:
          </p>

          <ul>
            <li>Google Play Services</li>
            <li>Google Auth</li>
            <li>Firebase Crashlytics</li>
            <li>Firebase Messaging</li>
            <li>Log Data</li>
          </ul>
          <h4>Log Data</h4>
          <p>
            We want to inform you that whenever you use our service, in case of
            an error in the app, we may collect data and information through
            third-party service providers on your phone called Log Data. This
            Log Data may include information such as your device Internet
            Protocol (“IP”) address, device name, operating system version, the
            configuration of the app when utilizing our service, the time and
            date of your use of the service, and other statistics.
          </p>
          <h4>Links to Other Sites</h4>
          <p>
            This service does not address anyone under the age of 13. We do not
            collect personal information from children under 13 years of age. If
            you are a parent or guardian and you are aware that your child has
            provided us with personal information, please contact us so that we
            may take the necessary actions.
          </p>
          <h4>Changes to This Privacy Policy</h4>
          <p>
            We may update our Privacy Policy from time to time. Thus, you are
            advised to review this page periodically for any changes. We will
            notify you of any changes by posting the new Privacy Policy on this
            page.
          </p>
          <p>This policy is effective as of Dec 06, 2024.</p>

          <h5>Contact Us</h5>
          <p>
            If you have any questions or suggestions about our Privacy Policy,
            do not hesitate to contact us at admin@triline.co.in
          </p>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
