import {
  useLocation,
  Link,
  useNavigate,
  NavigateFunction,
  useParams,
} from "react-router-dom";
import { NavPositionVariant, NavTopShapeVariant } from "config";
import { useAppContext } from "providers/AppProvider";
import { useEffect, useState } from "react";
import useSettingsMountEffect from "hooks/useSettingsMountEffect";
import Unicon from "components/base/Unicon";
import { UilCube, UilFilePlusAlt, UilPlus } from "@iconscout/react-unicons";
import { Col, Card, Row, Nav, NavLink } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import apiCall from "services/api";
import { useAuth } from "providers/AuthContext";
import { handleCutomError } from "services/handleCutomError";
import { toast, ToastContainer } from "react-toastify";
interface DataItem {
  id: number;
  title: string;
  body: string; // JSON string representing the `EditorBody` structure
}
const ProjectInfo = () => {
  const {
    config: { theme, navbarPosition },
    setConfig,
  } = useAppContext();
  const { userTkn, user, workSpaceTkn, signOut } = useAuth();
  const { alias, workspace } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const navigation = useNavigate() as NavigateFunction;
  const [data, setData] = useState<DataItem[]>([]);
  //set top navigation
  useEffect(() => {
    const value = "combo";
    const value2 = "slim";

    setConfig({
      navbarPosition: value as NavPositionVariant,
      navbarTopShape: value2 as NavTopShapeVariant,
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Set loading to true
        const response = await apiCall({
          url: `project/${alias}/documents`,
          method: "GET",
          headers: {
            "x-access-token": userTkn,
            workspace: workSpaceTkn,
          },
        });
        console.log("response", response?.data?.data?.document);
        if (response?.status === 200) {
          setData(response?.data?.data?.document);
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error("An error occurred:", error);
          // Display a generic error message to the user
          toast.error("An unexpected error occurred", {
            position: "top-center",
          });
        }
      } finally {
        setLoading(false); // Set loading to false after the API call is completed
      }
    };
    fetchData();
  }, []);

  useSettingsMountEffect({
    showSettingPanelButton: false,
  });

  return (
    <>
      <div>
        <div>
          <div className="d-flex flex-wrap mb-4  justify-content-between">
            <h2 className="mb-0">
              <Unicon icon={UilCube} size={28} />
              <span className="me-3">{" Project Info"}</span>
            </h2>
            <div className="d-flex align-items-center gap-2">
              <Link
                className="btn btn-outline-primary px-4 "
                to={`project-info-admin`}
              >
                Admin
              </Link>
              <Link className="btn btn-outline-primary px-4 " to="">
                <Unicon icon={UilFilePlusAlt} size={17} /> Files
              </Link>
              <Link className="btn btn-outline-primary px-4 " to="">
                <Unicon icon={UilPlus} size={17} /> Link
              </Link>
            </div>
          </div>

          <div className="d-md-block d-block">
            <Row>
              <Col lg={9}>
                <div className="Prject_infoDetails mb-3">
                  <h3 className="colorSet_heading mb-2">WIKI</h3>
                  <p className="fs-8 mb-0">
                    Oxfam India and YLAC have been working together to set up
                    Equality Clubs in schools across India. The aim is to raise
                    consciousness and appreciation of one’s own privilege, and
                    inspire students to take action.Oxfam India and YLAC have
                    been working together to set up Equality Clubs in schools
                    across India. The aim is to raise consciousness and
                    appreciation of one’s own privilege, and inspire students to
                    take action.Oxfam India and YLAC have been working together
                    to set up Equality Clubs in schools across India. The aim is
                    to raise consciousness and appreciation of one’s own
                    privilege, and inspire students to take action.Oxfam India
                    and YLAC have been working together to set up Equality Clubs
                    in schools across India. The aim is to raise consciousness
                    and appreciation of one’s own privilege, and inspire
                    students to take action.Oxfam India and YLAC have been
                    working together to set up Equality Clubs in schools across
                    India. The aim is to raise consciousness and appreciation of
                    one’s own privilege, and inspire students to take
                    action.Oxfam India and YLAC have been working together to
                    set up Equality Clubs in schools across India. The aim is to
                    raise consciousness and appreciation of one’s own privilege,
                    and inspire students to take action.
                  </p>
                  <div className="updatedate">
                    <div className="fs-8 mt-2">
                      {"Updated date: "}
                      <span className="fw-semi-bold">
                        10 Jan 2024, 12:47 PM
                      </span>
                    </div>
                  </div>
                </div>
                <div className="Prject_infoDetails">
                  <h4 className="colorSet_heading mb-2">Dummy</h4>
                  <p className="fs-8 mb-0">
                    Oxfam India and YLAC have been working together to set up
                    Equality Clubs in schools across India. The aim is to raise
                    consciousness and appreciation of one’s own privilege, and
                    inspire students to take action.Oxfam India and YLAC have
                    been working together to set up Equality Clubs in schools
                    across India. The aim is to raise consciousness and
                    appreciation of one’s own privilege, and inspire students to
                    take action.Oxfam India and YLAC have been working together
                    to set up Equality Clubs in schools across India. The aim is
                    to raise consciousness and appreciation of one’s own
                    privilege, and inspire students to take action.Oxfam India
                    and YLAC have been working together to set up Equality Clubs
                    in schools across India. The aim is to raise consciousness
                    and appreciation of one’s own privilege, and inspire
                    students to take action.Oxfam India and YLAC have been
                    working together to set up Equality Clubs in schools across
                    India. The aim is to raise consciousness and appreciation of
                    one’s own privilege, and inspire students to take
                    action.Oxfam India and YLAC have been working together to
                    set up Equality Clubs in schools across India. The aim is to
                    raise consciousness and appreciation of one’s own privilege,
                    and inspire students to take action.
                  </p>
                  <div className="updatedate">
                    <div className="fs-8 mt-2">
                      {"Updated date: "}
                      <span className="fw-semi-bold">
                        10 Jan 2024, 12:47 PM
                      </span>
                    </div>
                  </div>
                </div>
                <div  className="mb-3 mt-4">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item
                      eventKey="0"
                      className="bg-customs rounded-2 border-0"
                    >
                      <Accordion.Header className="fs-7">
                        Contents
                      </Accordion.Header>
                      <Accordion.Body className="pt-0">
                        <strong>
                          This is the first item's accordion body.
                        </strong>
                        It is shown by default, until the collapse plugin adds
                        the appropriate classes that we use to style each
                        element. These classes control the overall appearance,
                        as well as the showing and hiding via CSS transitions.
                        You can modify any of this with custom CSS or overriding
                        our default variables. It's also worth noting that just
                        about any HTML can go within the{" "}
                        <code>accordion-body</code>, though the transition does
                        limit overflow.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>
              <Col lg={3}>
                <Card className="projectInfo_card mb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h5 className="">Related Documents</h5>
                    </div>
                    <Link
                      to={{
                        pathname: `/${workspace}/project/${alias}/block-editor`,
                      }}
                      className="btn btn-outline-primary px-5"
                    >
                      {" "}
                      New
                    </Link>
                  </div>

                  <div className="d-flex flex-column gap-2">
                    {data?.map((item, index) => {
                      console.log(item);
                      return (
                        <Link
                          key={index}
                          to={{
                            pathname: `/${workspace}/project/${alias}/block-editor/${item?.id}`,
                          }}
                          state={{
                            title: item?.title,
                            body: item?.body,
                          }}
                        >
                          {item?.title}
                        </Link>
                      );
                    })}
                  </div>
                </Card>
                <Card className="projectInfo_card">
                  <Row className="pb-4">
                    <Col xs={12} className="mb-3">
                      <h4>Dummy Group 1</h4>
                    </Col>
                    <Row className="pb-2">
                      <Col md={5}>Translation:</Col>
                      <Col md={7}>
                        <p className="mb-0 fs-8 fw-bold">
                          English, Hindi, German
                        </p>
                      </Col>
                    </Row>
                    <Row className="pb-2">
                      <Col md={5}>Denouncing:</Col>
                      <Col md={7}>
                        <p className="mb-0 fs-8 fw-bold">Consequences</p>
                      </Col>
                    </Row>
                    <Row className="pb-2">
                      <Col md={5}>Necessitatibus:</Col>
                      <Col md={7}>
                        <p className="mb-0 fs-8 fw-bold">Voluptatibus</p>
                      </Col>
                    </Row>
                    <Row className="pb-2">
                      <Col md={5}>Project Status:</Col>
                      <Col md={7}>
                        <p className="mb-0 fs-8 fw-bold">Designing</p>
                      </Col>
                    </Row>
                  </Row>
                  <Row className="pb-4">
                    <Col xs={12} className="mb-3">
                      <h4>Dummy Group 1</h4>
                    </Col>
                    <Row className="pb-2">
                      <Col md={5}>Translation:</Col>
                      <Col md={7}>
                        <p className="mb-0 fs-8 fw-bold">
                          English, Hindi, German
                        </p>
                      </Col>
                    </Row>
                    <Row className="pb-2">
                      <Col md={5}>Denouncing:</Col>
                      <Col md={7}>
                        <p className="mb-0 fs-8 fw-bold">Consequences</p>
                      </Col>
                    </Row>
                    <Row className="pb-2">
                      <Col md={5}>Necessitatibus:</Col>
                      <Col md={7}>
                        <p className="mb-0 fs-8 fw-bold">Voluptatibus</p>
                      </Col>
                    </Row>
                    <Row className="pb-2">
                      <Col md={5}>Project Status:</Col>
                      <Col md={7}>
                        <p className="mb-0 fs-8 fw-bold">Designing</p>
                      </Col>
                    </Row>
                  </Row>
                  <Row className="pb-4">
                    <Col xs={12} className="mb-3">
                      <h4>Files</h4>
                    </Col>
                    <Row className="pb-2">
                      <Col md={5}>1.filesss</Col>
                      <Col md={7}>
                        <p className="mb-0 fs-8 fw-bold">
                          English, Hindi, German
                        </p>
                      </Col>
                    </Row>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <div className="Prject_infoDetails mb-3">
                  <h4 className="colorSet_heading mb-2">Dummy</h4>
                  <p className="fs-8 mb-0">
                    Oxfam India and YLAC have been working together to set up
                    Equality Clubs in schools across India. The aim is to raise
                    consciousness and appreciation of one’s own privilege, and
                    inspire students to take action.Oxfam India and YLAC have
                    been working together to set up Equality Clubs in schools
                    across India. The aim is to raise consciousness and
                    appreciation of one’s own privilege, and inspire students to
                    take action.Oxfam India and YLAC have been working together
                    to set up Equality Clubs in schools across India. The aim is
                    to raise consciousness and appreciation of one’s own
                    privilege, and inspire students to take action.Oxfam India
                    and YLAC have been working together to set up Equality Clubs
                    in schools across India. The aim is to raise consciousness
                    and appreciation of one’s own privilege, and inspire
                    students to take action.Oxfam India and YLAC have been
                    working together to set up Equality Clubs in schools across
                    India. The aim is to raise consciousness and appreciation of
                    one’s own privilege, and inspire students to take
                    action.Oxfam India and YLAC have been working together to
                    set up Equality Clubs in schools across India. The aim is to
                    raise consciousness and appreciation of one’s own privilege,
                    and inspire students to take action.
                  </p>
                  <div className="updatedate">
                    <div className="fs-8 mt-2">
                      {"Updated date: "}
                      <span className="fw-semi-bold">
                        10 Jan 2024, 12:47 PM
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectInfo;
