import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

export const handleCutomError = (error: any, signOut: () => void, navigate:any) => {

    if ((error as AxiosError).response?.status === 403) {
        // Display error toast message
        toast.error(error?.response?.data?.message, {
            position: "top-center"
        });

        // Sign out the user after displaying the error message
        setTimeout(() => {
            signOut();
        }, 3000);
    }else if((error as AxiosError).response?.status === 461){
        toast.error(error?.response?.data?.message, {
            position: "top-center"
        });
        setTimeout(() => {
            navigate('/admin/all-workspace/list')
        }, 3000);
    }else if((error as AxiosError).response?.status === 462){
        toast.error(error?.response?.data?.message, {
            position: "top-center"
        });
        setTimeout(() => {
            navigate('/admin/all-workspace/list')
        }, 3000);
    }else if((error as AxiosError).response?.status === 470){
        toast.error(error?.response?.data?.message, {
            position: "top-center"
        });
        setTimeout(() => {
            navigate('/admin/all-workspace/list')
        }, 3000);
    }else if((error as AxiosError).response?.status === 500){
        toast.error(error?.response?.data?.message, {
            position: "top-center"
        });
        setTimeout(() => {
         
        }, 3000);
    }else if((error as AxiosError).response?.status === 400){
        toast.error(error?.response?.data?.message, {
            position: "top-center"
        });
        setTimeout(() => {
         
        }, 3000);
    }else if((error as AxiosError).response?.status === 403){
        toast.error(error?.response?.data?.message, {
            position: "top-center"
        });
        setTimeout(() => {
         
        }, 3000);
    }else{
        toast.error(error?.message, {
            position: "top-center"
        });
    }
};
