import classNames from 'classnames';
import Footer from 'components/footers/Footer';
import NavbarDual from 'components/navbars/navbar-dual/NavbarDual';
import NavbarTopHorizontal from 'components/navbars/navbar-horizontal/NavbarTopHorizontal';
import NavbarTopDefault from 'components/navbars/navbar-top/NavbarTopDefault';
import NavbarVerical from 'components/navbars/navbar-vertical/NavbarVertical';
import NavbarVertical from 'components/navbars/navbar-vertical/NavbarVertical';
import NavbarVerticalCustom from 'components/navbars/navbar-vertical/custom-menu/NavbarVerticalCustom';

import { useAppContext } from 'providers/AppProvider';
import { useAuth } from 'providers/AuthContext';
import { useMainLayoutContext } from 'providers/MainLayoutProvider';
import { Container } from 'react-bootstrap';
import { Outlet, useLocation, useParams } from 'react-router-dom';

const MainLayout = () => {
  const { config: { navbarPosition } } = useAppContext();
  const { contentClass, footerClass } = useMainLayoutContext();
  const {pathname  } = useLocation()
  const {alias, } = useParams()
  const {workSpace} = useAuth()
 
  return (
    <Container fluid className="px-0">
      {/* {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        
        (pathname === `/project/${alias}/overview` || pathname === `/project/${alias}/setting`) ? <NavbarVerticalCustom alias={alias}/>: <NavbarVertical />
      )} */}
       {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        (pathname === `/${workSpace?.alias}/project/${alias}/overview` 
        || pathname === `/${workSpace?.alias}/project/${alias}/setting` 
        || pathname === `/${workSpace?.alias}/project/${alias}/project-update` 
        || pathname === `/${workSpace?.alias}/project/${alias}/project-info` 
        || pathname === `/${workSpace?.alias}/project/${alias}/project-info/project-info-admin`
        || pathname === `/${workSpace?.alias}/project/${alias}/action-point` 
        || pathname === `/${workSpace?.alias}/project/${alias}/project-info/project-info-admin/projectinfo-section`
        || pathname === `/${workSpace?.alias}/project/${alias}/notes`
        || pathname === `/${workSpace?.alias}/project/${alias}/project-story`) 
        && alias ? 
        // <NavbarVerticalCustom alias={alias} workspace={workSpace?.alias || ''}/> : <NavbarVertical />
        // <NavbarVerticalCustom alias={alias} workspace={workSpace?.alias || ''}/> : <div></div>
        <NavbarVerical alias={alias} workspace={workSpace?.alias || ''}/> : <div></div>
      )}
      {navbarPosition === 'vertical' && <NavbarTopDefault />}
      {(navbarPosition === 'horizontal' || navbarPosition === 'combo') && (
        <NavbarTopHorizontal />
      )}
      {navbarPosition === 'dual' && <NavbarDual />}

      <div className={classNames(contentClass, `content px-3 pt-7` )}>
        <Outlet />
        {/* <Footer className={classNames(footerClass, 'position-absolute')} /> */}
      </div>
    </Container>
  );
};

export default MainLayout;
