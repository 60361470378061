import { useAuth } from "providers/AuthContext";
import {  Table } from "react-bootstrap";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "providers/AppProvider";
import { useEffect, useState } from "react";
import { NavPositionVariant, NavTopShapeVariant } from "config";
import { useBreakpoints } from "providers/BreakpointsProvider";
import apiCall from "services/api";
import PhoenixDocCard from "components/base/PhoenixDocCard";
import { toast, ToastContainer } from 'react-toastify';
import { useMaster } from "providers/MasterContext";
import { handleCutomError } from "services/handleCutomError";
import PageBreadcrumb, { PageBreadcrumbItem } from "components/common/PageBreadcrumb";
import { getPageBreadCrumbs } from "services/getPageBreadCrumbs";


const AdminWorkSpaceSetting = () => {
    const initialBreadcrumb: PageBreadcrumbItem[] = [];
    const { workspace } = useParams();
    const [pageName, setPageName] = useState("ProjectList")
    const [breadCrumb, setBreadCrumb] = useState<PageBreadcrumbItem[]>(initialBreadcrumb);
    const navigation = useNavigate() as NavigateFunction;
    const [removeStatus, setRemoveStatus] = useState(false)
    const [loading, setLoading] = useState(false);
    const { setWorkspace, setWorkSpaceTkn, setIsUserAdmin, userTkn, workSpaceList, workSpace, setWorkspaceList, workSpaceTkn, isUserAdmin, signOut } = useAuth();
    const { refetchData } = useMaster();

    //update Breadcrumb
    useEffect(() => {
        getPageBreadCrumbs({ pageName, workspace, setBreadCrumb });
    }, [workspace, pageName, setBreadCrumb]);

    useEffect(() => {
        const fetchUserFromLocalStorage = async () => {

            try {

                const response = await apiCall({
                    url: `workspaces`,
                    method: 'GET',
                    headers: {
                        "x-api-key": "web_qwertyuiop",
                        "x-access-token": userTkn,
                        "Access-Control-Allow-Origin": "*",
                    },
                })

                if (response?.status === 200) {
                    let data = response?.data?.Workspaces?.data
                    if (data && data.length > 0 && workSpace) {
                       
                        setRemoveStatus(false)
                        const updatedWorkSpaceList = [workSpace, ...(response?.data?.Workspaces?.data).filter((workspace: any) => workspace.alias !== workSpace.alias)];

                        setWorkspaceList(updatedWorkSpaceList)
                        // setWorkspaceList(response?.data?.Workspaces?.data)
                    } else {
                        setWorkspaceList([])
                    }

                }

            } catch (error: any) {
                if (error?.isAxiosError) {
                    // Access the error message
                    handleCutomError(error, signOut, navigation);
                } else {
                    // Handle other types of errors
                    console.error('An error occurred:', error);
                    // Display a generic error message to the user
                    toast.error('An unexpected error occurred', {
                        position: "top-center"
                    });
                }
            } finally {
                setLoading(false); // Set loading to false after the API call is completed 
            }
        };
        if (userTkn) {
            fetchUserFromLocalStorage();
        }

    }, [userTkn, removeStatus]);

    const {
        config: {
            navbarPosition,
            openNavbarVertical,
            navbarTopShape,
            navbarTopAppearance

        },
        setConfig
    } = useAppContext();
    const { breakpoints } = useBreakpoints();

    useEffect(() => {
        const value = "combo";
        const value2 = 'slim';

        setConfig({
            navbarPosition: value as NavPositionVariant,
            navbarTopShape: value2 as NavTopShapeVariant
        });

    }, [])

    const handleNavItemClick = (item: any, event: any) => {
        event.preventDefault(); // Prevent default behavior of anchor tag
        setIsUserAdmin(item?.is_super_admin)
        setWorkSpaceTkn(item?.uuid)
        setWorkspace(item)

        // Rearrange workSpaceList to make the clicked item the first element
        const updatedWorkSpaceList = [item, ...workSpaceList.filter(workspace => workspace.alias !== item.alias)];
        setWorkspaceList(updatedWorkSpaceList);

        localStorage.setItem(
            "isUserAdmin",
            JSON.stringify(item?.is_super_admin)
        );
        localStorage.setItem(
            "workspaceToken",
            JSON.stringify(item?.uuid)
        );
        localStorage.setItem(
            "selectedWorkSpace",
            JSON.stringify(item)
        );

        navigation(`/${item.alias}/project/list`)
    }


    const LeaveWorkSoace = async (item: any) => {
        try {
            setLoading(true);
            const response = await apiCall({
                url: `admin/workspace/inactive`,
                method: 'POST',
                headers: {
                    'x-access-token': userTkn,
                    'workspace': item?.uuid
                },
            });

            if (response.status === 200) {
                refetchData()
                setRemoveStatus(true)
            } else {
                console.error("error message:");
            }
        } catch (error: any) {
            if (error?.isAxiosError) {
                // Access the error message
                handleCutomError(error, signOut, navigation);
            } else {
                // Handle other types of errors
                console.error('An error occurred:', error);
                // Display a generic error message to the user
                toast.error('An unexpected error occurred', {
                    position: "top-center"
                });
            }
        } finally {
            setLoading(false); // Set loading to false after the API call is completed
        }
    };

    return (
        <>
            <PageBreadcrumb items={breadCrumb} navigation={navigation} />
            <div className="d-flex flex-wrap mb-4 align-items-center">
                <h2 className="mb-0">
                    <span className="me-3">All Workspace </span>{" "}
                </h2>

            </div>
            <PhoenixDocCard className="p-sm-4 p-2 mt-3">
                {workSpaceList && workSpaceList.length === 0 ? (
                    <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900 mt-2">
                        <span className="text-600"> No WorkSpace assigned to You, Ask to Admin for assigning workspace!!</span>
                    </p>
                ) : (
                    <Table
                        className="phoenix-table fs-9 project-table border rounded"
                        size="sm"
                        striped
                        hover
                        responsive
                    >
                        <thead>
                            <tr id={"a1"}>
                                <th className="px-1" style={{ width: "70%", minWidth: "200px" }} >{"Workspace"}</th>
                                <th className="px-1" style={{ width: "15%", minWidth: "200px" }}> {"Action"}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {workSpaceList?.map((item, index) => (
                                <tr key={item?.id || index}>
                                    <td>
                                        <div
                                            key={index}
                                            className={`text-decoration-none fw-semi-bold current-workspace  fs-8 ${workSpace?.alias === item?.alias ? "current-active-workspace" : ""}`}
                                            onClick={(e) => handleNavItemClick(item, e)}
                                        >
                                            {item.title}
                                        </div>
                                    </td>
                                    <td>


                                        <div className={`text-decoration-none fw-semi-bold fs-8 workspace-btn-title ${workSpace?.alias === item?.alias ? "current-active-workspace" : ""}`}
                                            // onClick={() => { LeaveWorkSoace(item) }}
                                            onClick={(e) => handleNavItemClick(item, e)}
                                        >
                                            {workSpace?.alias === item?.alias ? "Active" : "Choose"}
                                        </div>

                                    </td>

                                </tr>

                            ))}

                        </tbody>
                    </Table>
                )
                }
            </PhoenixDocCard>
            <ToastContainer />
        </>

    );
};

export default AdminWorkSpaceSetting;
