

// https://ckeditor.com/docs/ckeditor5/latest/installation/integrations/react/react.html

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Row, Col, Container } from 'react-bootstrap';

const CkEditor = () => {


    return <Container>

        <Row className="justify-content-md-center">
        <Col className="" lg={3}></Col>
            <Col lg={6}>
               
                <CKEditor
                    editor={ClassicEditor}
                    data="<p>Hello from CKEditor&nbsp;5!</p>"
                    onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                  
                    }}
                    onChange={(event) => {
                   
                    }}
                    onBlur={(event, editor) => {
                     
                    }}
                    onFocus={(event, editor) => {
                   
                    }}
                />
            </Col>
            <Col lg={3}> </Col>
        </Row>

    </Container>;
};

export default CkEditor;
